<template>
  <v-container fluid class="pa-0">
     <v-container class="d-flex flex-wrap justify-center align-center">
     <v-col cols="12 mb-5" >
         <h1 class="page-title">Acordos e Convenções</h1>
      </v-col>
      <v-col v-if="!load" class="d-flex flex-column justify-center align-center" cols="12">
    <v-img src="../../../../assets/Pulse-0.7s-200px.svg" max-width="50"></v-img>
    <h5 class="pa-0">Carregando...</h5> 
  </v-col>
 <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

  <v-container>
      <v-expansion-panels>
    <v-expansion-panel
      v-for="(item,index) in list" :key="index"
    >
      <v-expansion-panel-header>{{item.name}}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card @click="downloadFile(file.name,file.path)" outlined class="my-2 d-flex" v-for="(file,index) in item.files" :key="index">
        <v-icon class="pa-2 blue--text">mdi-download</v-icon>
          <v-card-text>{{file.description}}</v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </v-container>

    </v-container>
   </v-container>
</template>

<script>

import agreementsService from '../../../../service/agreementsService'
import votingService from '../../../../service/votingService'

export default {
  name: "agreements",
  metaInfo: { 
    title: "SEEB | Acordos e Convenções",
     meta: [
      { name: 'description', content: 'Nossos Acordos e Convenções' }
    ]
    
  },
  data(){
    return{
      list:[],
      load:false,
      overlay:false
    }
  },
  components: {
      Footer: () => import("@/layouts/web/commons/Footer.vue"),
  },
  methods:{
     downloadFile(name,url) {
       
      const reportName = String(name);
      const type = /\.(csv|xls|xlsx|txt|pdf|xml|png|jpg|jpeg|gif|svg|doc|docx)$/i.exec(
        reportName
      )[1];

      const types = {
        pdf: "application/pdf",
        csv: "text/csv",
        xls: "application/vnd.ms-excel",
        xlsx:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        txt: "text/plain",
        xml: "application/xml"
      };

      const fileSplit = String(url).split("/");
      const file = fileSplit[fileSplit.length - 1];

      let fileData = {
        name: reportName,
        type: type,
        file: file
      };

      this.overlay = true
      votingService.downloadFile(fileData).then(res => {
        
        let base64 = btoa(
          new Uint8Array(res.data[0].data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        let sampleArr = this.base64ToArrayBuffer(base64);
        this.saveByteArray(reportName, types[type], sampleArr);
          this.overlay = false
          
      });
    },
    base64ToArrayBuffer(base64) {
      let binaryString = window.atob(base64);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },

    saveByteArray(reportName, type, byte) {
      let blob = new Blob([byte], { type: type });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = reportName;
      link.download = fileName;
      link.click();
    },
  },
  mounted(){
    agreementsService.get({all:true}).then(res => {
      const data = res.data
      const dates = []
      this.load = true
       const datesGroup = data.map(element => {
         if(dates.indexOf(element.dates) === -1){
           dates.push(element.dates)
         }
        return {date:element.dates, description:element.description,name:element.name, path:element.path}
      });
      this.list = dates.map(date => {
        return {name:date,files:[]}
      })
      datesGroup.forEach(group => {
        this.list[dates.indexOf(group.date)].files.push(group)
        
      });
    })
  }
}
</script>

<style lang="sass" scoped>
  .page-title 
    background: #114979
    color: #fff
    font-family: Raleway,sans-serif
    font-weight: 700
    line-height: 1
    margin: 0
    font-size: 1.75rem
    padding: 1.25rem 1.25rem
</style>
